var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "border-shadow-thin" },
    [
      _c(
        "v-card-title",
        { staticClass: "pl-0 pr-0 pt-0" },
        [
          _c("TableSearch", {
            attrs: { label: "Pesquisar...", delay: 0 },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c("v-spacer"),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.uploads,
          search: _vm.search,
          "sort-by": ["uploadDateTime"],
          "sort-desc": [true],
          "footer-props": {
            "items-per-page-options": [10, 25, 50],
          },
        },
        scopedSlots: _vm._u([
          {
            key: "item.uploadDateTime",
            fn: function ({ item }) {
              return [
                _c(
                  "tr",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.showUploadHistory(item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("date")(item.uploadDateTime, {
                            mask: "DD/MM/YY HH:mm:ss",
                            showEmpty: true,
                          })
                        ) +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c("modalUploadArquivoHistory", { ref: "modalUploadArquivoHistory" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }