<template>
  <v-card class="border-shadow-thin">
    <v-card-title class="pl-0 pr-0 pt-0">
      <TableSearch
        label="Pesquisar..."
        v-model="search"
        :delay="0"
      ></TableSearch>
      <v-spacer />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="uploads"
      :search="search"
      :sort-by="['uploadDateTime']"
      :sort-desc="[true]"
      :footer-props="{
        'items-per-page-options': [10, 25, 50],
      }"
    >
      <template v-slot:item.uploadDateTime="{ item }">
        <tr @click="showUploadHistory(item)">
          {{
            item.uploadDateTime | date({ mask: 'DD/MM/YY HH:mm:ss', showEmpty: true })
          }}
        </tr>
      </template>
    </v-data-table>
    <modalUploadArquivoHistory ref="modalUploadArquivoHistory" />
  </v-card>
</template>

<script>
import 'moment/locale/pt-br';
import eSocialService from '@/services/eSocialService.js';

export default {
  props: {
    message: { type: String },
  },

  components: {
    modalUploadArquivoHistory: () => import('./ModalUploadArquivoHistory.vue'),
    TableSearch: () => import('../../commons/TableSearch.vue'),
  },

  data() {
    return {
      search: '',
      error: false,
      headers: [
        {
          text: 'Nome do arquivo',
          align: 'start',
          sortable: false,
          value: 'fileName',
        },
        { text: 'Qtd. de XMLs', value: 'amount' },
        { text: 'Data', value: 'uploadDateTime' },
        { text: 'CNPJ', value: 'nrInsc' },
        { text: 'Usuário', value: 'userUploadXmls' },
        { text: 'Situação do processo', value: 'statusProcess' },
      ],

      uploads: [],
    };
  },

  mounted() {
    this.getAll();
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.$on('refresh', this.delay);
    },
    delay() {
      this.sleep(300).then(() => {
        this.getAll();
      });
    },

    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    open() {
      this.getAll();
    },
    async getAll() {
      try {
        let response = await eSocialService.eSocialUpload.getAll();
        this.error = false;

        this.uploads = response.data;
        this.uploads.map((u) => {
          //   //u.date = moment(u.uploadDateTime).format('DD/MM/YY HH:mm:ss');
          u.fileName = u.fileNameZip.split('_')[1].split('.zip')[0];
        });
      } catch (error) {
        this.error = true;
      }
    },
    async showUploadHistory(fileProcess) {
      this.$refs.modalUploadArquivoHistory.$emit('open', fileProcess);
    }
  },
};
</script>
